import React,{useState} from 'react'
import { navigate } from 'gatsby'
import clsx from 'clsx'
import * as Cookies from 'js-cookie';
import Swal from "sweetalert2";
import GoBack from '../../../../components/pricing/goBack'
import { useDispatch, useSelector } from 'react-redux'
import Video from "../../../../components/Video/video";
import putPricingState from '../../../../procedures/pricing/putPricingState'
import packPricingObject from '../../../../functions/pricing/packPricingObject'
import ContactUs from '../../../../components/contactUs'
import Register from '../../../../components/Register'
import PRICING_ACTIONS from '../../../../redux/actions/Pricing'
import styles2 from '../../../../components/contactUs/styles.module.css'
import './styles.css'

const PricingCourse1Layout = () => {
  const [registerForm , setRegisterForm] = useState(false);
  const Pricing = useSelector((state) => state.Pricing)
  const dispatch = useDispatch();

  const setCourse1 = () => {
    if(Pricing.verifyCount === 0){
        setRegisterForm(true);
    }else{
      const setAnswer = PRICING_ACTIONS.setCourse1(1)
        dispatch(setAnswer)

        let pricingObject = packPricingObject(Pricing)
        pricingObject.course1 = 1
        const idCookie = Cookies.getJSON('opz-pricing-id')
        const id = idCookie ? idCookie.id : null
        if (!id) {
          return () => {
          }
        }
        putPricingState(Pricing.id, pricingObject);
        Swal.fire(
          'Congratulation',
          'Welcome to the Product Launch Formula',
          'success'
        )
        navigate('/cost-to-make-an-app/course2');
    }
  }

  return (
    <>
      <div className="container">
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <div className="container">
              <h1 className="section__h1">Product Marketing Formula</h1>
              <p className="section__p">
                There is a simple and proven path to successful product launches.
                Simple does not mean easy. It is a lot of work.
              </p>
              <p>
                This path is :
                <ul>
                  <li><b>fast</b> : So you can get to a market quickly.</li>
                  <li><b>low risk</b> : because you can get sales even before your product is built.</li>
                  <li><b>low investment</b> : When you are on the proven path, we can get investors to pitch in because they see success & speed are highly likely.</li>
                  <li><b>full of examples</b> : in case studies to show you how others have done it.</li>
                  <li><b>complete with useful metrics</b> : which tell you what to expect and what to prioritize.</li>
                  <li><b>your guide with strategy & playbooks</b> : based on what has worked in the past.</li>
                </ul>
              </p>
              <p>
                Jeff Walker started teaching the product marketing formula in 2005. This idea has
                grown into a movement. Here is an introduction to the Product Marketing Formula.
              </p>
            </div>
            <Video src={'https://media.opzoom.com/app.opzoom.com/product-marketing/intro.m4v'} type={'video/mp4'}/>

          </div>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <h1 className="section__h1">Get the Product Marketing Formula</h1>
            <p>
              The next step in this path explains how the product marketing formula
              works in a <b>6 minute</b> video.
            </p>
            <p>
              This is the dominant path to product launches. You will recognize it
              when you see it. It show you how to build confidence in your product
              with users.
            </p>
            <p>
              This is a must have for your success.<br/>
              Sign up for this path below.
            </p>

            {registerForm && <Register page="course1"/>}
            {!registerForm && <center>
                <button type="button" className="button" onClick={() => {
                  setCourse1()
                }}>
                  Start: Product Marketing Path
                </button>
            </center>}
          </div>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <h1 className="section__h1">Get Funded</h1>
            <p>
              Most of the technology companies such as Facebook,
              Apple, Microsoft, Google and Amazon were funded because of a strategic reason.
            </p>
            <p>
              Learn about the playbook behind selling a part of a winning company
              at an attractive price to make it worth far more.
            </p>

            <center>
                <button type="button" className="button" onClick={() => {
                  navigate('/cost-to-make-an-app/course2');
                }}>
                  All about Funding
                </button>
            </center>
          </div>
        </center>

        {(Pricing.verifyCount === 0) && <ContactUs/>}

      </div>

      <GoBack prevQuestionId={'samples'}/>
    </>
  )
}
export default PricingCourse1Layout
