import React from "react";
import PropTypes from "prop-types";
import Head from "../../components/Head/Head";
import Header from "../../components/Header/Header";
import PricingCourse1Layout from "../../layouts/pricing/question/course1";
import HeaderPlaceholder from "../../components/common/HeaderPlaceholder/HeaderPlaceholder";

const VideoPage = ({ location }) => {

  return (
    <>
      <Head />
      <Header pageId={location.pathname} />
      <section className="full-section">
        <HeaderPlaceholder />
        <PricingCourse1Layout />
        
      </section>
    </>
  );
};

VideoPage.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default VideoPage;
